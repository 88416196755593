const players = [
  { name: 'Christian McCaffrey', pos: 'RB', team: 'CAR' },
  { name: 'Dalvin Cook', pos: 'RB', team: 'MIN' },
  { name: 'Derrick Henry', pos: 'RB', team: 'TEN' },
  { name: 'Ezekiel Elliott', pos: 'RB', team: 'DAL' },
  { name: 'Saquon Barkley', pos: 'RB', team: 'NYG' },
  { name: 'Travis Kelce', pos: 'TE', team: 'KC' },
  { name: 'Jonathan Taylor', pos: 'RB', team: 'IND' },
  { name: 'Nick Chubb', pos: 'RB', team: 'CLE' },
  { name: 'Aaron Jones', pos: 'RB', team: 'GB' },
  { name: 'Stefon Diggs', pos: 'WR', team: 'BUF' },
  { name: 'DeAndre Hopkins', pos: 'WR', team: 'ARI' },
  { name: 'Calvin Ridley', pos: 'WR', team: 'ATL' },
  { name: 'Joe Mixon', pos: 'RB', team: 'CIN' },
  // eslint-disable-next-line prettier/prettier
  { name: "D'Andre Swift", pos: 'RB', team: 'DET' },
  { name: 'Najee Harris', pos: 'RB', team: 'PIT' },
  { name: 'George Kittle', pos: 'TE', team: 'SF' },
  { name: 'Clyde Edwards-Helaire', pos: 'RB', team: 'KC' },
  { name: 'Darren Waller', pos: 'TE', team: 'LV' },
  { name: 'A.J. Brown', pos: 'WR', team: 'TEN' },
  { name: 'Miles Sanders', pos: 'RB', team: 'PHI' },
  { name: 'Terry McLaurin', pos: 'WR', team: 'WAS' },
  { name: 'David Montgomery', pos: 'RB', team: 'CHI' },
  { name: 'James Robinson', pos: 'RB', team: 'JAX' },
  { name: 'Keenan Allen', pos: 'WR', team: 'LAC' },
  { name: 'Allen Robinson II', pos: 'WR', team: 'CHI' },
  { name: 'CeeDee Lamb', pos: 'WR', team: 'DAL' },
  { name: 'Josh Jacobs', pos: 'RB', team: 'LV' },
  { name: 'Mike Evans', pos: 'WR', team: 'TB' },
  { name: 'Gus Edwards', pos: 'RB', team: 'BAL' },
  { name: 'Robert Woods', pos: 'WR', team: 'LAR' },
  { name: 'Amari Cooper', pos: 'WR', team: 'DAL' },
  { name: 'Chris Carson', pos: 'RB', team: 'SEA' },
  { name: 'DJ Moore', pos: 'WR', team: 'CAR' },
  { name: 'Chris Godwin', pos: 'WR', team: 'TB' },
  { name: 'Myles Gaskin', pos: 'RB', team: 'MIA' },
  { name: 'Diontae Johnson', pos: 'WR', team: 'PIT' },
  { name: 'Julio Jones', pos: 'WR', team: 'TEN' },
  { name: 'Mark Andrews', pos: 'TE', team: 'BAL' },
  { name: 'Cooper Kupp', pos: 'WR', team: 'LAR' },
  { name: 'Mike Davis', pos: 'RB', team: 'ATL' },
  { name: 'Adam Thielen', pos: 'WR', team: 'MIN' },
  { name: 'T.J. Hockenson', pos: 'TE', team: 'DET' },
  { name: 'Kyle Pitts', pos: 'TE', team: 'ATL' },
  { name: 'Tyler Lockett', pos: 'WR', team: 'SEA' },
  { name: 'Kareem Hunt', pos: 'RB', team: 'CLE' },
  { name: 'Kyler Murray', pos: 'QB', team: 'ARI' },
  { name: 'Brandon Aiyuk', pos: 'WR', team: 'SF' },
  { name: 'Tee Higgins', pos: 'WR', team: 'CIN' },
  { name: 'Javonte Williams', pos: 'RB', team: 'DEN' },
  { name: 'Kenny Golladay', pos: 'WR', team: 'NYG' },
  { name: 'Chase Claypool', pos: 'WR', team: 'PIT' },
  { name: 'Lamar Jackson', pos: 'QB', team: 'BAL' },
  { name: 'Darrell Henderson Jr.', pos: 'RB', team: 'LAR' },
  { name: 'Dak Prescott', pos: 'QB', team: 'DAL' },
  { name: 'Chase Edmonds', pos: 'RB', team: 'ARI' },
  { name: 'Robby Anderson', pos: 'WR', team: 'CAR' },
  { name: 'Raheem Mostert', pos: 'RB', team: 'SF' },
  // eslint-disable-next-line prettier/prettier
  { name: "Ja'Marr Chase", pos: 'WR', team: 'CIN' },
  { name: 'Courtland Sutton', pos: 'WR', team: 'DEN' },
  { name: 'Noah Fant', pos: 'TE', team: 'DEN' },
  { name: 'Michael Carter', pos: 'RB', team: 'NYJ' },
  { name: 'Odell Beckham Jr.', pos: 'WR', team: 'CLE' },
  { name: 'Trey Sermon', pos: 'RB', team: 'SF' },
  { name: 'Dallas Goedert', pos: 'TE', team: 'PHI' },
  { name: 'Aaron Rodgers', pos: 'QB', team: 'GB' },
  { name: 'DJ Chark Jr.', pos: 'WR', team: 'JAX' },
  { name: 'JuJu Smith-Schuster', pos: 'WR', team: 'PIT' },
  { name: 'Justin Herbert', pos: 'QB', team: 'LAC' },
  { name: 'Deebo Samuel', pos: 'WR', team: 'SF' },
  { name: 'Ronald Jones II', pos: 'RB', team: 'TB' },
  { name: 'Russell Wilson', pos: 'QB', team: 'SEA' },
  { name: 'Melvin Gordon III', pos: 'RB', team: 'DEN' },
  { name: 'Jerry Jeudy', pos: 'WR', team: 'DEN' },
  { name: 'Damien Harris', pos: 'RB', team: 'NE' },
  { name: 'Tyler Boyd', pos: 'WR', team: 'CIN' },
  { name: 'Leonard Fournette', pos: 'RB', team: 'TB' },
  { name: 'Michael Thomas', pos: 'WR', team: 'NO' },
  { name: 'David Johnson', pos: 'RB', team: 'HOU' },
  { name: 'Brandin Cooks', pos: 'WR', team: 'HOU' },
  { name: 'Zack Moss', pos: 'RB', team: 'BUF' },
  { name: 'James Conner', pos: 'RB', team: 'ARI' },
  { name: 'Tom Brady', pos: 'QB', team: 'TB' },
  { name: 'Jarvis Landry', pos: 'WR', team: 'CLE' },
  { name: 'Jalen Hurts', pos: 'QB', team: 'PHI' },
  { name: 'Logan Thomas', pos: 'TE', team: 'WAS' },
  { name: 'Mike Gesicki', pos: 'TE', team: 'MIA' },
  { name: 'DeVonta Smith', pos: 'WR', team: 'PHI' },
  { name: 'Tyler Higbee', pos: 'TE', team: 'LAR' },
  { name: 'Curtis Samuel', pos: 'WR', team: 'WAS' },
  { name: 'AJ Dillon', pos: 'RB', team: 'GB' },
  { name: 'Ryan Tannehill', pos: 'QB', team: 'TEN' },
  { name: 'Antonio Brown', pos: 'WR', team: 'TB' },
  { name: 'Joe Burrow', pos: 'QB', team: 'CIN' },
  { name: 'Kenyan Drake', pos: 'RB', team: 'LV' },
  { name: 'Devin Singletary', pos: 'RB', team: 'BUF' },
  { name: 'Will Fuller V', pos: 'WR', team: 'MIA' },
  { name: 'Robert Tonyan', pos: 'TE', team: 'GB' },
  { name: 'Matthew Stafford', pos: 'QB', team: 'LAR' },
  { name: 'Nyheim Hines', pos: 'RB', team: 'IND' },
  { name: 'Laviska Shenault Jr.', pos: 'WR', team: 'JAX' },
  { name: 'Trevor Lawrence', pos: 'QB', team: 'JAX' },
  { name: 'Irv Smith Jr.', pos: 'TE', team: 'MIN' },
  { name: 'Evan Engram', pos: 'TE', team: 'NYG' },
  { name: 'Mike Williams', pos: 'WR', team: 'LAC' },
  { name: 'Michael Gallup', pos: 'WR', team: 'DAL' },
  { name: 'Rob Gronkowski', pos: 'TE', team: 'TB' },
  { name: 'Tony Pollard', pos: 'RB', team: 'DAL' },
  { name: 'Marquise Brown', pos: 'WR', team: 'BAL' },
  { name: 'Mecole Hardman', pos: 'WR', team: 'KC' },
  { name: 'Matt Ryan', pos: 'QB', team: 'ATL' },
  { name: 'Corey Davis', pos: 'WR', team: 'NYJ' },
  { name: 'Jamaal Williams', pos: 'RB', team: 'DET' },
  { name: 'J.D. McKissic', pos: 'RB', team: 'WAS' },
  { name: 'Kirk Cousins', pos: 'QB', team: 'MIN' },
  { name: 'Jonnu Smith', pos: 'TE', team: 'NE' },
  { name: 'Michael Pittman Jr.', pos: 'WR', team: 'IND' },
  { name: 'Latavius Murray', pos: 'RB', team: 'NO' },
  { name: 'Jaylen Waddle', pos: 'WR', team: 'MIA' },
  { name: 'DeVante Parker', pos: 'WR', team: 'MIA' },
  { name: 'Daniel Jones', pos: 'QB', team: 'NYG' },
  { name: 'Austin Hooper', pos: 'TE', team: 'CLE' },
  { name: 'Sony Michel', pos: 'RB', team: 'LAR' },
  { name: 'James White', pos: 'RB', team: 'NE' },
  { name: 'Phillip Lindsay', pos: 'RB', team: 'HOU' },
  { name: 'Ben Roethlisberger', pos: 'QB', team: 'PIT' },
  { name: 'Baker Mayfield', pos: 'QB', team: 'CLE' },
  { name: 'Adam Trautman', pos: 'TE', team: 'NO' },
  { name: 'Alexander Mattison', pos: 'RB', team: 'MIN' },
  { name: 'Marvin Jones Jr.', pos: 'WR', team: 'JAX' },
  { name: 'T.Y. Hilton', pos: 'WR', team: 'IND' },
  { name: 'Justin Tucker', pos: 'K', team: 'BAL' },
  { name: 'Ryan Fitzpatrick', pos: 'QB', team: 'WAS' },
  { name: 'Darnell Mooney', pos: 'WR', team: 'CHI' },
  { name: 'Hunter Henry', pos: 'TE', team: 'NE' },
  { name: 'Nelson Agholor', pos: 'WR', team: 'NE' },
  { name: 'Kenneth Gainwell', pos: 'RB', team: 'PHI' },
  { name: 'Harrison Butker', pos: 'K', team: 'KC' },
  { name: 'Jared Cook', pos: 'TE', team: 'LAC' },
  { name: 'Blake Jarwin', pos: 'TE', team: 'DAL' },
  { name: 'Steelers D/ST', pos: 'DST', team: 'PIT' },
  { name: 'Henry Ruggs III', pos: 'WR', team: 'LV' },
  { name: 'Cole Kmet', pos: 'TE', team: 'CHI' },
  { name: 'Zach Ertz', pos: 'TE', team: 'PHI' },
  { name: 'Cole Beasley', pos: 'WR', team: 'BUF' },
  { name: 'Rams D/ST', pos: 'DST', team: 'LAR' },
  { name: 'Rashaad Penny', pos: 'RB', team: 'SEA' },
  { name: 'Elijah Moore', pos: 'WR', team: 'NYJ' },
  { name: 'Younghoe Koo', pos: 'K', team: 'ATL' },
  { name: 'Tevin Coleman', pos: 'RB', team: 'NYJ' },
  { name: 'Derek Carr', pos: 'QB', team: 'LV' },
  { name: 'Giovani Bernard', pos: 'RB', team: 'TB' },
  { name: 'Trey Lance', pos: 'QB', team: 'SF' },
  { name: 'Tua Tagovailoa', pos: 'QB', team: 'MIA' },
  { name: 'Greg Zuerlein', pos: 'K', team: 'DAL' },
  { name: 'Colts D/ST', pos: 'DST', team: 'IND' },
  { name: 'Jalen Reagor', pos: 'WR', team: 'PHI' },
  { name: 'Ravens D/ST', pos: 'DST', team: 'BAL' },
  { name: 'Chuba Hubbard', pos: 'RB', team: 'CAR' },
  { name: 'Darrel Williams', pos: 'RB', team: 'KC' },
  { name: 'Jason Myers', pos: 'K', team: 'SEA' },
  { name: 'Gerald Everett', pos: 'TE', team: 'SEA' },
  { name: 'Anthony Firkser', pos: 'TE', team: 'TEN' },
  { name: 'Chiefs D/ST', pos: 'DST', team: 'KC' },
  { name: "Tre'Quan Smith", pos: 'WR', team: 'NO' },
  { name: 'Sam Darnold', pos: 'QB', team: 'CAR' },
  { name: 'Gabriel Davis', pos: 'WR', team: 'BUF' },
  { name: 'Jason Sanders', pos: 'K', team: 'MIA' },
  { name: 'Sterling Shepard', pos: 'WR', team: 'NYG' },
  { name: 'Justin Fields', pos: 'QB', team: 'CHI' },
  { name: 'Tyler Bass', pos: 'K', team: 'BUF' },
  { name: 'Rodrigo Blankenship', pos: 'K', team: 'IND' },
  { name: 'Deshaun Watson', pos: 'QB', team: 'HOU' },
  { name: 'Patriots D/ST', pos: 'DST', team: 'NE' },
  { name: 'Russell Gage', pos: 'WR', team: 'ATL' },
  { name: 'Salvon Ahmed', pos: 'RB', team: 'MIA' },
  { name: 'Rondale Moore', pos: 'WR', team: 'ARI' },
  { name: 'Eric Ebron', pos: 'TE', team: 'PIT' },
  { name: 'Christian Kirk', pos: 'WR', team: 'ARI' },
  { name: 'Jameis Winston', pos: 'QB', team: 'NO' },
  { name: 'Saints D/ST', pos: 'DST', team: 'NO' },
  { name: 'Ryan Succop', pos: 'K', team: 'TB' },
  { name: 'Tarik Cohen', pos: 'RB', team: 'CHI' },
  { name: 'Damien Williams', pos: 'RB', team: 'CHI' },
  { name: 'John Brown', pos: 'WR', team: 'LV' },
  { name: 'Emmanuel Sanders', pos: 'WR', team: 'BUF' },
  { name: 'Rashod Bateman', pos: 'WR', team: 'BAL' },
  { name: 'Robbie Gould', pos: 'K', team: 'SF' },
  { name: 'Jamison Crowder', pos: 'WR', team: 'NYJ' },
  { name: 'Darrynton Evans', pos: 'RB', team: 'TEN' },
  { name: '49ers D/ST', pos: 'DST', team: 'SF' },
  { name: 'Parris Campbell', pos: 'WR', team: 'IND' },
];

export default players;

const algo = {
  /* eslint-disable */
  find: 'd+. (.+), (.+), (.+), .+$',
  replace: '{name: "$1", pos: "$2",  team: "$3"},',
};

const keepers = [
  { name: 'Alvin Kamara', pos: 'RB', team: 'NO' },
  { name: 'Austin Ekeler', pos: 'RB', team: 'LAC' },
  { name: 'Davante Adams', pos: 'WR', team: 'GB' },
  { name: 'Tyreek Hill', pos: 'WR', team: 'KC' },
  { name: 'DK Metcalf', pos: 'WR', team: 'SEA' },
  { name: 'Antonio Gibson', pos: 'RB', team: 'WAS' },
  { name: 'Patrick Mahomes', pos: 'QB', team: 'KC' },
  { name: 'Justin Jefferson', pos: 'WR', team: 'MIN' },
];
